<template>
    <li class="item-container" :style="{ 'height': `${height}px` }">
        <work-calendar-time-line :is-today="isToday" :title="dateStr" :is-last="isLast"></work-calendar-time-line>
        <work-calendar-card :is-today="isToday" :item="item"
            @onSelectCellDetail="(it, index) => { $emit('onSelectCellDetail', it, index) }"></work-calendar-card>
    </li>
</template>

<script>
import WorkCalendarCard from './work-calendar-card/work-calendar-card.vue'
import WorkCalendarTimeLine from './work-calendar-time-line.vue'
export default {
    components: {
        WorkCalendarCard,
        WorkCalendarTimeLine
    },
    props: {
        isToday: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
        dateStr: {
            type: String,
            default: '',
        },
        isLast: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            height: 0,
        }
    },
    created() {

    },
    mounted() {

    },
    watch: {
        item: {
            handler(oldVal, newVal) {
                const list = this.item?.cardList
                    .filter(el => {
                        if (el.cardType == 'therapy_data_normal') {
                            return true
                        }
                        if (el.cardType == 'todo_ding_report' || el.cardType == 'todo_adaptability_report') {
                            if (this.isToday) {
                                return el.numerator !== null && el.denominator !== 0 && el.denominator !== null
                            } else {
                                return el.numerator !== null
                            }
                        }
                        return el.percent !== null
                    })
                this.height = list.length > 6 ? (430 + Math.floor((list.length - 6 + 2) / 3) * 147.5) : 430
            },
            immediate: true,
        },
    },
    methods: {

    },
}
</script>
<style scoped lang='scss'>
.item-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 40px;
    min-height: 430px;
    box-sizing: border-box;
}
</style>