<template>
    <div class="table-container">
        <!-- ------------表格--------------->
        <div class="table">
            <el-table class="zb-table" v-loading="loading" :data="data" :border="false" empty-text="无数据">
                <template v-for="item in columns">
                    <el-table-column v-if="item.type" :type="item.type" :width="item.width"
                        :align="item.align != null ? item.align : 'center'" :fixed="item.fixed" :label="item.label" />
                    <el-table-column v-else :prop="item.name" :width="item.width"
                        :align="item.align != null ? item.align : 'center'" :fixed="item.fixed" :label="item.label">
                        <template #default="scope">
                            <span v-if="!item.slot">{{ scope.row[item.name] }}</span>
                            <slot v-else :name="item.name" :item="item" :row="scope.row"></slot>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
        </div>
        <!-- ------------分页--------------->
        <div class="pagination" v-if="paginationData">
            <el-pagination :current-page="paginationData.pageNum" :page-size="pageSize" background
                layout="->, prev, pager, next" :total="paginationData.total" @current-change="handleCurrentChange">
                <!-- <span class="pagination-total">共 {{ paginationData.total }} 项数据</span> -->
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { Table, TableColumn, Pagination, Loading } from 'element-ui'
export default {
    components: {
        ElTable: Table,
        ElTableColumn: TableColumn,
        ElPagination: Pagination,
    },
    directives: {
        Loading,
    },
    props: {
        columns: {
            type: Array,
            default: [],
        },
        data: {
            type: Array,
            default: [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        pageSize: {
            type: Number,
            default: 12,
        },
        paginationData: {
            type: Object,
            default: {
                pageNum: 1,
                pageSize: 0,
                total: 0,
                pages: 0,
                boolLastPage: true,
            },
        },
    },
    data() {
        return {

        }
    },
    methods: {
        handleCurrentChange(val) {
            this.$emit('handleCurrentChange', val)
        }
    },
}
</script>
<style scoped lang='scss'>
.table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    overflow: hidden;
    background: #FFFFFF;
    // min-height: 900px;

    .table {
        margin: 0px 26px 30px 17px;
    }

    .zb-table {
        height: 100%;
    }

    /deep/.el-table__header th {
        font-size: 12px;
        color: #000000;
    }

    .pagination {
        // box-sizing: border-box;
        margin: 0px 16px 30px;

        // &-total {
        //     font-size: 13px;
        //     font-weight: 400;
        //     color: rgba(0, 0, 0, 0.6);
        // }
    }
}
</style>