<template>
    <li class="workcalendar-backlog-item-container">
        <div class="workcalendar-backlog-item-content">
            <el-descriptions :column="5" :title="ifEmpty(item.patientName, '-')" labelClassName="item-label">
                <el-descriptions-item label="服务包类型" :span="2">{{ packageTypeStr(item.packageType) }}</el-descriptions-item>
                <el-descriptions-item label="当前服务包状态" :span="3">
                    <package-status-item :package-status="item.packageState"></package-status-item>
                </el-descriptions-item>
                <el-descriptions-item label="医生" :span="2">{{ifEmpty(item.doctorName, '-')}}</el-descriptions-item>
                <el-descriptions-item label="医助" :span="3">{{ifEmpty(item.doctorAssistantName, '-')}}</el-descriptions-item>
                <el-descriptions-item label="医院" :span="4" label-class-name="item-label">{{ifEmpty(item.hospitalName, '-')}}</el-descriptions-item>
            </el-descriptions>
            <button class="workcalendar-backlog-item-button" :class="[isShow ? 'item-button-green' : 'item-button-blue']" @click.stop.prevent="$emit('onClickOperation')">{{ isShow ? '查看' : '处理'}}</button>
        </div>
    </li>
</template>

<script>
import PackageStatusItem from './package-status-item.vue';
import { Descriptions, DescriptionsItem } from 'element-ui';
import { ifEmpty } from "@js/empty-tool.js"
export default {
    components: {
        ElDescriptions: Descriptions,
        ElDescriptionsItem: DescriptionsItem,
        PackageStatusItem
    },
    props: {
        item: {
            type: Object,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        isShow() {
            return this.item?.operation == 'view'
        }
    },
    methods: {
        ifEmpty,
        packageTypeStr(packageType) {
            return packageType == 1 ? '适宜性评估服务包' : '辅助管理服务包'
        },
    },
}
</script>

<style scoped lang='scss'>
.workcalendar-backlog-item-container {
    padding: 16px 28px 0px;

    .workcalendar-backlog-item-content {
        padding: 12px 16px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 0px rgba(139, 139, 139, 0.5);
        border-radius: 6px;
        position: relative;

        /deep/ tbody:last-child .el-descriptions-row .el-descriptions-item__cell {
            padding-bottom: 0px;
        }

        /deep/ .el-descriptions__title {
            color: #1677FF;
        }

        .workcalendar-backlog-item-button {
            position: absolute;
            bottom: 12px;
            right: 16px;
            width: 90px;
            height: 31px;
            border-radius: 3px;
            font-size: 14px;
        }

        .item-button-blue {
            color: #1677FF;
            background: #E6F4FF;
            border: 1px solid #BAE0FF;
        }

        .item-button-green {
            color: #52C41A;
            background: #F6FFED;
            border: 1px solid #D9F7BE;
        }

    }
}
</style>

<style>
.item-label {
    color: #808080;
}
</style>