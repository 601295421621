<template>
  <div class="card-container">
    <card-title class="card-title" :is-today="isToday" :report-count="item.reportNum"
      :abnormal-count="ifEmpty(item.abnormalNum, 0)" :percentage="isEmpty(item.percent) ? 0 : +item.percent"></card-title>
    <div class="card-cell-group">
      <card-cell v-for="(item, index) in cellList" :key="index" class="card-cell" :is-today="isToday"
        :denominator="item.denominator" :numerator="item.numerator" :title="item.title" :sub-title="item.subTitle"
        :type="item.type" @onSelectDetail="onSelectCellDetail(item, index)"></card-cell>
    </div>
  </div>
</template>

<script>
import CardCell from './card-cell.vue'
import CardTitle from './card-title.vue'
import { ifEmpty, isEmpty } from "@js/empty-tool.js"
export default {
  components: {
    CardCell,
    CardTitle,
  },
  props: {
    isToday: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    cellList() {
      return this.item?.cardList
        .filter(el => {
          if (el.cardType == 'therapy_data_normal') {
            return true
          }
          if (el.cardType == 'todo_ding_report' || el.cardType == 'todo_adaptability_report') {
            if (this.isToday) {
              return el.numerator !== null && el.denominator !== 0 && el.denominator !== null
            } else {
              return el.numerator !== null
            }
          }
          return el.percent !== null
        })
        .map(el => {
          let title = ''
          let subTitle = ''
          let type = ''
          switch (el.cardType) {
            case 'todo_ding_report':
              title = this.isToday ? '待办事项' : '已办事项';
              subTitle = '待生成滴定报告';
              type = 'white'
              break;
            case 'todo_adaptability_report':
              title = this.isToday ? '待办事项' : '已办事项';
              subTitle = '待生成适宜性评估报告';
              type = 'white'
              break;
            case 'therapy_data_normal':
              title = '治疗数据无异常';
              type = 'green'
              break;
            case 'therapy_data_severe':
              title = '治疗数据异常级别：高';
              type = 'red'
              break;
            case 'therapy_data_moderate':
              title = '治疗数据异常级别：中';
              type = 'yellow'
              break;
            case 'therapy_data_mild':
              title = '治疗数据异常级别：低';
              type = 'blue'
              break;
            case 'no_use_respirator':
              title = '未使用呼吸机患者';
              type = 'turquoise'
          }
          return {
            title,
            numerator: ifEmpty(el.numerator, 0),
            denominator: ifEmpty(el.denominator, 0),
            cardType: el.cardType,
            type,
            subTitle,
          }
        }) ?? []
    },

  },
  methods: {
    onSelectCellDetail(item, index) {
      this.$emit('onSelectCellDetail', { ...item }, index)
    },
    ifEmpty,
    isEmpty
  },
}
</script>
<style scoped lang='scss'>
.card-container {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 26px 13px 12px;
  box-sizing: border-box;

  .card-title {
    height: 42px;
  }

  .card-cell-group {
    width: 100%;
    margin-top: 14px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .card-cell {
    flex-grow: 0;
    width: 33.3%;
  }
}
</style>