<template>
    <div v-if="tableData.length != 0">
        <prop-table :loading="loading" @handleCurrentChange="onCurrentChange" :columns="columns" :data="tableData"
            :page-size="pageSize" :pagination-data="paginationData">
            <template #name="scope">
                <div class="user-name">
                    <span class="unused"
                        v-if="scope.row.data.daysUnused >= 5 && (type=='no_use_respirator' || type =='all')">{{
                        scope.row.data.daysUnused }}</span>
                    <button class="name"
                        :class="[(scope.row.data.daysUnused >= 5 && (type=='no_use_respirator' || type =='all')) ? 'name-tag' : 'name-notag']"
                        type="button" @click.prevent.stop="onClickName(scope.row)">{{ scope.row.name
                        }}</button>
                </div>
            </template>
            <template #ahi="scope">
                <color-score-item :value1="scope.row.ahi1" :value2="scope.row.ahi2"
                    :level="scope.row.ahiColor"></color-score-item>
            </template>
            <template #leak="scope">
                <color-score-item :value1="scope.row.leak1" :value2="scope.row.leak2"
                    :level="scope.row.leakColor"></color-score-item>
            </template>
            <template #time="scope">
                <color-score-item :value1="scope.row.time1" :value2="scope.row.time2"
                    :level="scope.row.timeColor"></color-score-item>
            </template>
            <template #compliance="scope">
                <color-score-item :value1="scope.row.compliance1" :value2="scope.row.compliance2"
                    :level="scope.row.complianceColor"></color-score-item>
            </template>
            <template #operation="scope">
                <el-tag :type="scope.row.handleStatus == 0 ? '' : 'success'" @click="onClickOperation(scope.row)"
                    style="cursor: pointer">{{ scope.row.operation }}</el-tag>
            </template>
        </prop-table>
        <work-calendar-abnormal-handler ref="abnormalHandlerRef" @refresh="onRefresh(pageNum)"
            :key="abnormalHandlerKey"></work-calendar-abnormal-handler>

        <all-report-list-alert v-if="isShowAllReportListAlert" :uid="alertUid" :hospitalId="alertHostpitalId"
            @onCloseClick="onSleepListAlertClose"></all-report-list-alert>
    </div>
    <el-empty v-else description="暂无数据"></el-empty>
</template>

<script>
import PropTable from '../../common/prop-table/prop-table.vue'
import ColorScoreItem from './color-score-item.vue'
import { Tag, Empty } from 'element-ui'
import WorkCalendarAbnormalHandler from '../work-calendar-abnormal-handler/work-calendar-abnormal-handler.vue'
import { DateTool } from '@js/date-tool'
import { ifEmpty, isEmpty } from '@js/empty-tool'
export default {
    components: {
        PropTable,
        ColorScoreItem,
        ElTag: Tag,
        WorkCalendarAbnormalHandler,
        ElEmpty: Empty,
        AllReportListAlert: () =>
            import("@c/common/alert/panel-alert/panel-alert-all-report-list.vue"),
    },
    props: {
        timestemp: {
            type: Number,
            default: 0,
        },
        // 日历卡片类型 all:全部, therapy_data_normal:治疗数据无异常, therapy_data_severe:治疗数据级别 高, therapy_data_moderate:治疗数据级别 中, therapy_data_mild:治疗数据级别 低, no_use_respirator: 无报告
        type: {
            type: String,
            default: 'all',
        },
    },
    data() {
        return {
            loading: false,
            columns: [
                { name: 'name', label: '姓名', align: 'center', slot: true, width: 130 },
                { name: 'sn', label: 'SN', align: 'center' },
                { name: 'ahi', label: 'AHI', align: 'center', slot: true, width: 80 },
                { name: 'leak', label: '漏气L/min', align: 'center', slot: true, width: 80 },
                { name: 'time', label: '使用时长', align: 'center', slot: true, width: 150 },
                { name: 'compliance', label: '依从性', align: 'center', slot: true, width: 100 },
                { name: 'operation', label: '操作', align: 'center', width: 80, slot: true },
            ],
            pageSize: 10,
            pageNum: 1,
            paginationData: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
                pages: 0,
                boolLastPage: true,
            },
            abnormalHandlerKey: 0,
            list: [],

            isShowAllReportListAlert: false,
            breathListAlertTypeIndex: undefined,
        }
    },
    created() {
    },
    computed: {
        tableData() {
            return this.list.map(el => {
                const timeStr = DateTool.secondsToHoursMinutes(el.longTime)
                return {
                    patientId: el.patientId,
                    reportId: el.id,
                    name: ifEmpty(el.patientName, '-'),
                    sn: ifEmpty(el.sn, '-'),
                    ahi1: isEmpty(el.ahi) ? '-' : (+el.ahi).toFixed(1),
                    ahi2: isEmpty(el.ahi) ? '-' : ahiLevel(+el.ahi)[1].toString(),
                    ahiColor: ahiLevel(+el.ahi)[0],
                    leak1: isEmpty(el.leakage) ? '-' : (+el.leakage).toFixed(1),
                    leak2: isEmpty(el.leakage) ? '-' : leakLevel(+el.leakage)[1].toString(),
                    leakColor: leakLevel(+el.leakage)[0],
                    time1: isEmpty(el.longTime) ? '-' : timeStr.toString(),
                    time2: isEmpty(el.longTime) ? '-' : `${timeLevel(el.longTime)[1]}小时`,
                    timeColor: timeLevel(el.longTime)[0],
                    compliance1: isEmpty(el.compliance) ? '-' : `${ifEmpty(el.compliance, '-')}%`,
                    compliance2: isEmpty(el.compliance) ? '-' : `${complianceLevel(+el.compliance)[1]}%`,
                    complianceColor: complianceLevel(+el.compliance)[0],
                    handleStatus: el.handleStatus,
                    data: el,
                    operation: el.handleStatus == 0 ? '处理' : '查看',
                }
            })
        }
    },
    mounted() {
        this.onRefresh();
    },
    methods: {
        onCurrentChange(value) {
            this.onRefresh(value);
        },
        async onRefresh(value = 1) {
            this.pageNum = value;
            try {
                const data = await this.$api.getWorkCalendarExceptionList({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    start: this.timestemp,
                    end: this.timestemp,
                    cardType: this.type,
                    hospitalId: this.$store.state.organizationModel?.id,
                })
                this.list.splice(0, this.list.length, ...data.list);
                this.paginationData = { ...data }
            } catch (error) {

            }
        },
        async onClickName(item) {
            //弹出呼吸机使用报告列表
            this.alertUid = item?.patientId;
            this.alertHostpitalId = item?.data?.hospitalId;
            this.breathListAlertTypeIndex = null;
            this.isShowAllReportListAlert = true;
        },

        onSleepListAlertClose() {
            this.isShowAllReportListAlert = false;
        },

        async onClickOperation(item) {
            if (item.patientId == 0) {
                this.$message.error('患者不存在');
                return
            }
            this.abnormalHandlerKey++;
            await this.$nextTick();
            this.$refs.abnormalHandlerRef.show(item);
        }
    },
}
</script>
<style scoped lang='scss'>
.user-name {
    display: flex;
    align-items: center;
    .unused {
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 15px;  
        color: white;
        background-color: #FA2C2C;
        border-radius: 15px;
        -webkit-transform: scale(0.67);
    }
    .name {
        height: 17px;
        // width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #1677FF;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .name-tag {
        width: calc(100% - 30px);
        text-align: left;
    }
    .name-notag {
        width: 100%;
        padding-left: 30px;
        text-align: left;
    }
}
</style>