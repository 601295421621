<template>
    <div class="work-calendar-backlog-container">
        <template v-if="list.length != 0">
            <ul class="work-calendar-backlog-list">
                <work-calendar-backlog-item v-for="item, index in list" :key="index" :item="item"
                    @onClickOperation="onClickOperation(item)"></work-calendar-backlog-item>
            </ul>
            <div class="work-calendar-backlog-pagination">
                <el-pagination :current-page="pageNum" :page-size="pageSize" background layout="->, prev, pager, next"
                    :total="total" @current-change="handleCurrentChange">
                    <!-- <span class="pagination-total">共 {{ paginationData.total }} 项数据</span> -->
                </el-pagination>
            </div>
        </template>
        <el-empty v-else description="暂无数据"></el-empty>
    </div>
</template>

<script>
import PropTable from '../../common/prop-table/prop-table.vue'
import WorkCalendarBacklogItem from './work-calendar-backlog-item.vue'
import { Pagination, Empty } from 'element-ui'
export default {
    components: {
        PropTable,
        WorkCalendarBacklogItem,
        ElPagination: Pagination,
        ElEmpty: Empty,
    },
    props: {
        timestemp: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: 'all',
        },
    },
    data() {
        return {
            list: [],
            pageSize: 5,
            pageNum: 1,
            total: 0,
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        handleCurrentChange(val) {
            this.pageNum = val;
            this.loadData()
        },
        async loadData() {
            try {
                const data = await this.$api.getWorkCalendarTodoList({ pageNum: this.pageNum, pageSize: this.pageSize, start: this.timestemp, end: this.timestemp, hospitalId: this.$store.state.organizationModel?.id})
                this.list.splice(0, this.list.length,...data.list);
                this.total = data.total ?? 0;
            } catch (error) {

            }
        },
        onClickOperation(item) {
            if (item.operation == 'view') {
                const uid = item.patientId;
                if (!uid) {
                    return;
                }
                let routeData = this.$router.resolve({
                    path: "user-main",
                    query: {
                        uid,
                        moduleName:'Record',
                    },
                });
                window.open(routeData.href, "_blank");
            } else {
                let recordType = item.packageState == 7 ? 1 : 2
                this.navigatorToRecordFrame({
                    patientId: item.patientId,
                    hospitalid: item.hospitalId,
                    templateType: recordType,
                    recordState: 1
                })
            }
        },
        navigatorToRecordFrame(model) {
            let routeData = this.$router.resolve({
                name: "RecordFrame",
                query: {
                    ...model
                },
            });
            window.open(routeData.href, "_blank");
        }
    },
}
</script>
<style scoped lang='scss'>
.work-calendar-backlog-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    min-height: 900px;

    .work-calendar-backlog-list {
        margin-bottom: 20px;
    }

    .work-calendar-backlog-pagination {
        margin: 0px 16px 30px;
    }
}
</style>