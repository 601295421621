<template>
  <div class="work-calendar-container container-width">
    <div class="work-calendar-container-list-container">
      <ul class="work-calendar-container-list" v-infinite-scroll="load" :infinite-scroll-distance="0"
        :infinite-scroll-immediate="false" :infinite-scroll-disabled="disabled">
        <work-calendar-item v-for="item, index in list" :key="index" :item="item" :date-str="item.time" :is-today="index == 0"
          :is-last="index == list.length - 1"
          @onSelectCellDetail="(el, index) => onSelectCellDetail(el, item.time)"></work-calendar-item>
      </ul>
    </div>
    <work-calendar-detail ref="workCalendarDetailRef"></work-calendar-detail>
  </div>
</template>

<script>
import WorkCalendarItem from './work-calendar-item.vue'
import { InfiniteScroll } from 'element-ui'
import WorkCalendarDetail from './work-calendar-detail/work-calendar-detail.vue'
export default {
  components: { WorkCalendarItem, WorkCalendarDetail },
  directives: {
    InfiniteScroll
  },
  data() {
    return {
      disabled: false,
      pageIndex: 0,
      pageSize: 5,
      list: []
    }
  },
  computed: {
    timestemp() {
      const date = new Date(this.endTimestemp);
      date.setDate(date.getDate() - (this.pageSize - 1));
      return date.getTime();
    },
    endTimestemp() {
      const now = new Date();
      now.setDate(now.getDate() - this.pageIndex * this.pageSize);
      return now.getTime();
    },
  },
  watch: {
    '$store.state.organizationModel': {
      handler(newVal, oldVal) {
        if (newVal?.id != oldVal?.id) {
          this.refresh();
        }
      }
    }
  },
  methods: {
    async load() {
      try {
        const data = await this.$api.getWorkCalendarPageList({ start: this.timestemp, end: this.endTimestemp, hospitalId: this.$store.state.organizationModel?.id });
        this.pageIndex++;
        this.list.push(...data);
      } catch (error) {

      }
    },
    async refresh() {
      try {
        this.pageIndex = 0;
        const data = await this.$api.getWorkCalendarPageList({ start: this.timestemp, end: this.endTimestemp, hospitalId: this.$store.state.organizationModel?.id });
        this.pageIndex++;
        this.list.splice(0, this.list.length, ...data);
      } catch (error) {

      }
    },
    onSelectCellDetail(item, time) {
      let newIndex = 0;
      switch (item.cardType) {
        case 'todo_ding_report': break;
        case 'todo_adaptability_report': break;
        case 'therapy_data_normal':
          newIndex = 5;
          break;
        case 'therapy_data_severe':
          newIndex = 2;
          break;
        case 'therapy_data_moderate':
          newIndex = 3;
          break;
        case 'therapy_data_mild':
          newIndex = 4;
          break;
        case 'no_use_respirator':
          newIndex = 6;
          break
      }
      const timestemp = new Date(time.replace(/年|月/g, '-').replace('日', '')).getTime();
      this.$refs.workCalendarDetailRef.show(newIndex, timestemp);
    },
  },
}
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.work-calendar-container {
  height: 1000px;
  min-width: 1280px;
  background-color: #F2F2F7;
  box-shadow: -14px 8px 24px 1px #F2F2F7;
}
.container-width {
  width: 100vw;
}


.work-calendar-container-list-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.work-calendar-container-list {
  overflow-y: hidden;
}
</style>