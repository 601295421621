<template>
    <work-calendar-abnormal-handler-dialog :dialog-title="ifEmpty(exceptionDetail?.patientName, '-')"
        :dialog-desc="ifEmpty(exceptionDetail?.sn, '-')" dialog-width="756px" :dialog-visible="dialogVisible" @close="hide"
        @onClickHeaderTitle="onClickHeaderTitle">
        <work-calendar-abnormal-content :exceptionDetail="exceptionDetail" v-loading="loading"
            @refresh="onRefresh()"></work-calendar-abnormal-content>
    </work-calendar-abnormal-handler-dialog>
</template>

<script>
import WorkCalendarAbnormalHandlerDialog from './work-calendar-abnormal-handler-dialog.vue';
import WorkCalendarAbnormalContent from './work-calendar-abnormal-content/work-calendar-abnormal-content.vue';
import { ifEmpty } from '@js/empty-tool';
import { Loading } from 'element-ui'
export default {
    components: {
        WorkCalendarAbnormalHandlerDialog,
        WorkCalendarAbnormalContent
    },
    directives: {
        Loading,
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            reportId: 0,
            exceptionDetail: null,
        }
    },
    created() {

    },
    mounted() {
    },
    methods: {
        ifEmpty,
        async load() {
            this.loading = true;
            try {
                const data = await this.$api.getWorkCalendarExceptionDetail({
                    id: this.reportId,
                });
                this.exceptionDetail = data;
            } catch (error) {

            } finally {
                this.loading = false;
            }
        },
        show(item) {
            this.dialogVisible = true;
            this.reportId = item.reportId;
            this.load();
        },
        hide() {
            this.dialogVisible = false;
        },
        onClickHeaderTitle() {
            //跳转到呼吸机治疗
            const uid = this.exceptionDetail?.patientId;
            const moduleName = 'Breath'
            if (!uid) {
                return
            }
            let routeData = this.$router.resolve({
                path: "/user-main",
                query: {
                    uid,
                    moduleName,
                },
            });
            window.open(routeData.href, "_blank");
        },
        onRefresh() {
            this.load();
            this.$emit('refresh');
        }
    },
}
</script>
<style scoped lang='scss'></style>