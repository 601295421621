<template>
    <div class="card-title-container" :style="{ '--mainColor': mainColor }">
        <el-progress class="progress" type="circle" :percentage="percentage" :color="mainColor" :text-color="mainColor"
            :stroke-width="4" :width="50"></el-progress>
        <h2 class="title">{{ title }}</h2>
    </div>
</template>

<script>
import { Progress } from "element-ui";
import { ifEmpty } from "@js/empty-tool.js"
export default {
    components: {
        elProgress: Progress
    },
    props: {
        reportCount: {
            type: Number,
            default: 1,
        },

        abnormalCount: {
            type: Number,
            default: 0
        },
        isToday: {
            type: Boolean,
            default: false
        },
        percentage: {
            type: Number,
            default: 100,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        // isToday == true
        //1.暂无待办，今日使用数据无异常, 2.xx人待出报告，今日使用数据无异常，
        // 3.暂无待办，xx人使用数据异常需关注, 4.xx人待出报告，xx人使用数据异常需关注
        // else
        //1.暂无已办，今日使用数据无异常, 2.xx人已出报告，今日使用数据无异常，
        // 3.暂无已办，xx人使用数据异常需关注, 4.xx人已出报告，xx人使用数据异常需关注
        title() {
            if (this.reportCount == null && this.abnormalCount == 0) {
                return `暂无${this.isToday ? '待' : '已'}办，${this.isToday ? '今日' : ''}使用数据无异常`
            } else if (this.reportCount != null && this.abnormalCount == 0) {
                return `${ifEmpty(this.reportCount, '0')}人${this.isToday ? '待' : '已'}出报告，${this.isToday ? '今日' : ''}使用数据无异常`
            } else if (this.reportCount == null && this.abnormalCount != 0) {
                return `暂无${this.isToday ? '待' : '已'}办，${ifEmpty(this.abnormalCount, '0')}人使用数据异常需关注`
            } else {
                return `${ifEmpty(this.reportCount, '0')}人${this.isToday ? '待' : '已'}出报告，${ifEmpty(this.abnormalCount, '0')}人使用数据异常需关注`
            }
        },
        mainColor() {
            return this.isToday ? '#1677FF' : '#8C8C8C'
        },
    },
}
</script>
<style scoped lang='scss'>
.card-title-container {
    display: flex;
    align-items: center;

    .progress {
        width: 50px;
        height: 50px;
    }

    /deep/.el-progress .el-progress__text {
        font-size: 16px !important;
    }

    .title {
        margin: 0px;
        margin-left: 17px;
        height: 33px;
        font-size: 24px;
        color: var(--mainColor);
        line-height: 33px;
    }
}
</style>