<template>
    <div class="abnormal-content-handler-container">
        <el-input type="textarea" :rows="5" :placeholder="exceptionDetail?.handleStatus != 0 ? '' : '请输入处理方法'"
            v-model="handlerText" :disabled="!isEmpty(exceptionDetail?.handleStatus) && exceptionDetail?.handleStatus != 0">
        </el-input>
        <div class="operation" v-if="!isEmpty(exceptionDetail?.handleStatus) && exceptionDetail?.handleStatus == 0">
            <!-- <el-select v-model="handlerSilence" placeholder="沉默通道" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select> -->
            <button class="operation-cancel" type="button" @click.stop.prevent="$emit('cancel')"
                :disabled="handlerLoading">忽略</button>
            <button class="operation-confirm" type="button" @click.stop.prevent="$emit('confirm')"
                :disabled="handlerLoading">处理</button>
        </div>
    </div>
</template>

<script>
import { Input, Select, Option } from 'element-ui';
import { isEmpty } from '@js/empty-tool';
export default {
    components: {
        ElInput: Input,
        ElSelect: Select,
        ElOption: Option
    },
    props: {
        text: {
            type: String,
            default: '',
        },
        silence: {
            type: Number,
            default: null,
        },
        exceptionDetail: {
            type: Object,
            default: null,
        },
        handlerLoading: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            options: [{
                value: 1,
                label: '一天'
            }, {
                value: 2,
                label: '三天'
            }, {
                value: 3,
                label: '七天'
            }]
        }
    },
    computed: {
        handlerText: {
            get() {
                return this.text;
            },
            set(val) {
                this.$emit('update:text', val);
            }
        },
        handlerSilence: {
            get() {
                return this.silence;
            },
            set(val) {
                this.$emit('update:silence', val);
            }
        },
    },
    created() {
    },
    methods: {
        isEmpty,
    },
}
</script>
<style scoped lang='scss'>
@mixin operaton-button {
    width: 60px;
    height: 32px;
    border-radius: 3px;
    font-size: 14px;
}

.abnormal-content-handler-container {
    padding: 18px 27px;
    :deep(.el-textarea.is-disabled .el-textarea__inner ) {
        color: #333;
        background-color: white;
    }

    .operation {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        :deep(.el-select) {
            width: 120px;
            height: 32px;
            margin-right: 8px;

        }

        :deep(.el-input__inner) {
            height: 32px;
            line-height: 32px;
        }

        :deep(.el-input__icon) {
            height: 32px;
            line-height: 32px;
        }

        .operation-cancel {
            @include operaton-button;
            background: #FFFFFF;
            border: 1px solid rgba(151, 151, 151, 0.17);
            color: rgba(0, 0, 0, 0.9);
            margin-right: 8px;
        }

        .operation-confirm {
            @include operaton-button;
            background: #2779FF;
            color: rgba(255, 255, 255, 0.9);
        }
    }
}
</style>