<template>
    <div class="abnormal-content-chart-container">
        <div class="date-info">
            <div class="date-info-tag">历史指标</div>
            <div class="date-info-value">{{ anchorDate != null ? getPastWeekRange(anchorDate) : '' }}</div>
            <el-radio-group v-model="dateScope" @input="onInputChange" fill="#2779FF">
                <el-radio-button label="周"></el-radio-button>
                <el-radio-button label="月"></el-radio-button>
            </el-radio-group>
            <div class="chart-color-info">
                <div v-for="(item, index) in chartColorInfo" :key="index" class="chart-color-info-item">
                    <div class="chart-color-info-item-color" :style="{ 'background-color': item.color }"></div>
                    <div class="chart-color-info-item-title">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <work-calendar-abnormal-content-data class="info-content"
            :info-data="infoData"></work-calendar-abnormal-content-data>
        <div ref="chartContainer" :style="`width: 100%; height: 295px `"></div>
    </div>
</template>

<script>
import { RadioButton, RadioGroup } from 'element-ui';
import WorkCalendarAbnormalContentData from './work-calendar-abnormal-content-data.vue';
import { isEmpty, ifEmpty } from '@js/empty-tool';
import { DateTool } from '@js/date-tool';
export default {
    components: {
        ElRadioButton: RadioButton,
        ElRadioGroup: RadioGroup,
        WorkCalendarAbnormalContentData,
    },
    props: {
        exceptionDetail: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dateScope: '周',
            infoData: [],
            loading: false,
            chartColorInfo: [
                {
                    title: 'AHI',
                    color: '#FE3F40'
                },
                {
                    title: '使用时长',
                    color: '#2480FF'
                },
                {
                    title: '漏气',
                    color: '#52C41A'
                },
                {
                    title: '依从性',
                    color: '#FF9950'
                },
            ],
            weekList: [],
            monthList: [],
            chart: null,
        }
    },
    computed: {
        anchorDate() {
            return this.dateFromDateStr(this.exceptionDetail?.reportDate)
        },
        startDate() {
            return this.isWeek ? (new Date(this.anchorDate.getFullYear(), this.anchorDate.getMonth(), this.anchorDate.getDate() - 6)) : (new Date(this.anchorDate.getFullYear(), this.anchorDate.getMonth(), this.anchorDate.getDate() - 29));
        },
        isWeek() {
            return this.dateScope == '周'
        },
        xAxis() {
            if (!this.anchorDate) {
                return [];
            }
            let endDate = this.anchorDate;
            let startDate = this.startDate;

            let rangeArray = [];
            let currentDate = startDate;

            while (currentDate <= endDate) {
                let monthString = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                let dayString = currentDate.getDate().toString().padStart(2, '0');
                let dateString = `${monthString}月${dayString}日`;
                rangeArray.push(dateString);
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return rangeArray;
        },
        ahiChartData() {
            const list = this.isWeek ? this.weekList : this.monthList;
            const dataList = list.map(el => el.ahi ? +el.ahi : null)
            return this.convert(dataList, 0);
        },
        timeChartData() {
            const list = this.isWeek ? this.weekList : this.monthList;
            const dataList = list.map(el => el.longTime)
            return this.convert(dataList, 1);
        },
        leakChartData() {
            const list = this.isWeek ? this.weekList : this.monthList;
            const dataList = list.map(el => el.leakage ? +el.leakage : null)
            return this.convert(dataList, 2);
        },
        complianceChartData() {
            const list = this.isWeek ? this.weekList : this.monthList;
            const dataList = list.map(el => el.compliance ? +el.compliance : null)
            return this.convert(dataList, 3);
        },
    },
    watch: {
        exceptionDetail(val, oldVal) {
            this.load()
        }
    },
    created() {
    },
    mounted() {
        const app = document.getElementById("app");
        this.chart = this.$echarts.init(this.$refs.chartContainer);
        let that = this;
        this.chart.on('highlight', function (params) {

            that.infoData.splice(0, that.infoData.length);
            const timeStr = !isEmpty(that.timeChartData[params.batch[1].dataIndex].realValue) ? DateTool.secondsToHoursMinutes(that.timeChartData[params.batch[1].dataIndex].realValue) : ''
            that.infoData.push({
                title: 'AHI',
                value1: !isEmpty(that.ahiChartData[params.batch[0].dataIndex].realValue) ? that.ahiChartData[params.batch[0].dataIndex].realValue.toFixed(1) : '',
                value2: !isEmpty(that.ahiChartData[params.batch[0].dataIndex].min) ? that.ahiChartData[params.batch[0].dataIndex].min.toString() : '',
                level: that.ahiChartData[params.batch[0].dataIndex].level,
            })
            that.infoData.push({
                title: '使用时长',
                value1: timeStr,
                value2: !isEmpty(timeStr) ? `${that.timeChartData[params.batch[1].dataIndex].min}小时` : '',
                level: that.timeChartData[params.batch[1].dataIndex].level,
            })
            that.infoData.push({
                title: '漏气',
                value1: !isEmpty(that.leakChartData[params.batch[2].dataIndex].realValue) ? that.leakChartData[params.batch[2].dataIndex].realValue.toFixed(1) : '',
                value2: !isEmpty(that.leakChartData[params.batch[2].dataIndex].min) ? that.leakChartData[params.batch[2].dataIndex].min.toString() : '',
                level: that.leakChartData[params.batch[2].dataIndex].level,
            })
            that.infoData.push({
                title: '依从性',
                value1: !isEmpty(that.complianceChartData[params.batch[3].dataIndex].realValue) ? `${that.complianceChartData[params.batch[3].dataIndex].realValue}%` : '',
                value2: !isEmpty(that.complianceChartData[params.batch[3].dataIndex].min) ? `${that.complianceChartData[params.batch[3].dataIndex].min}%` : '',
                level: that.complianceChartData[params.batch[3].dataIndex].level,
            })
        });
        window.onresize = () => {
            this.chart.resize();
        };
    },
    destroyed() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                const data = await this.$api.getWorkCalendarHistoricIndicators({
                    patientId: this.exceptionDetail.patientId,
                    statisticType: this.isWeek ? 1 : 2,
                    start: +this.startDate,
                    end: +this.anchorDate
                })
                this.isWeek ? this.weekList.splice(0, data.length, ...data) : this.monthList.splice(0, data.length, ...data);
                this.drawLine();
            } catch (error) {

            } finally {
                this.loading = false;
            }
        },
        onInputChange() {
            if (!this.exceptionDetail) {
                return
            }
            if (this.isWeek && this.weekList.length == 0) {
                this.load();
            } else if (!this.isWeek && this.monthList.length == 0) {
                this.load();
            } else {
                this.drawLine();
            }
        },
        convert(val, type) {
            let data = val.map((item, index) => {
                if (item === null) {
                    return {
                        value: undefined,
                        realValue: undefined,
                        level: undefined,
                        min: undefined,
                    }
                }
                const value = item ?? 0;
                let levelOption = [];
                if (type == 0) levelOption = ahiLevel(item);
                else if (type == 1) levelOption = timeLevel(item);
                else if (type == 2) levelOption = leakLevel(item);
                else levelOption = complianceLevel(item);
                let yValue = 0
                if (type == 1) {
                    yValue = (levelOption[0] + 1) * 10 - ((value / 3600 - levelOption[1]) / (levelOption[2] - levelOption[1]) * 10);
                } else if (type == 3) {
                    yValue = (levelOption[0] + 1) * 10 - ((value - levelOption[1]) / (levelOption[2] - levelOption[1]) * 10);
                } else {
                    yValue = ((value - levelOption[1]) / (levelOption[2] - levelOption[1]) * 10) + levelOption[0] * 10;
                }
                return {
                    value: yValue,
                    realValue: value,
                    level: levelOption[0],
                    // min: (type == 0 || type == 2) ? levelOption[1] : levelOption[2],
                    min: levelOption[1],
                };
            })
            return data;
        },
        drawLine() {
            const that = this;
            this.chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    showContent: false,
                },
                grid: {
                    top: 15,
                    left: '0%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    data: this.xAxis,
                    axisLabel: {
                        interval(index, value) {
                            if (that.isWeek) {
                                return true;
                            } else {
                                if (index == 0 || index == 29) {
                                    return true;
                                } else {
                                    return index % 5 == 0;
                                }
                            }
                        }
                    },
                    animation: false,
                },
                yAxis: [
                    {
                        type: 'category',
                        data: ['正常', '低异常', '中异常', '高异常'],
                        axisLine: { show: false },
                        axisTick: { show: false },

                    },
                    {
                        type: 'value',
                        splitNumber: 4, //分段数
                        max: 40,
                        min: 0,
                        axisLine: { show: false },
                        axisTick: { show: false },
                        splitArea: {
                            show: true,
                            areaStyle: {
                                color: ['#F6FFED', '#FFFBE6', '#E6F7FF', '#FFF1F0']
                            }
                        },
                        axisLabel: { show: false },
                    },
                ],
                series: [
                    {
                        name: 'AHI',
                        type: 'line',
                        data: this.ahiChartData,
                        yAxisIndex: 1,
                        itemStyle: { color: '#9A9A9A' },
                        lineStyle: { color: '#FE3F40' },
                        emphasis: {
                            itemStyle: {
                                borderColor: '#FE3F40',
                            },
                        }
                    },
                    {
                        name: '使用时长',
                        type: 'line',
                        data: this.timeChartData,
                        yAxisIndex: 1,
                        itemStyle: { color: '#9A9A9A' },
                        lineStyle: { color: '#2480FF' },
                        emphasis: {
                            itemStyle: { borderColor: '#2480FF' },
                        }
                    },
                    {
                        name: '漏气',
                        type: 'line',
                        data: this.leakChartData,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: '#9A9A9A',
                        },
                        lineStyle: {
                            color: '#52C41A',
                        },
                        emphasis: {
                            itemStyle: {
                                borderColor: '#52C41A',
                            },
                        }
                    },
                    {
                        name: '依从性',
                        type: 'line',
                        data: this.complianceChartData,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: '#9A9A9A',
                        },
                        lineStyle: {
                            color: '#FF9950',
                        },
                        emphasis: {
                            itemStyle: {
                                borderColor: '#FF9950',
                            },
                        }
                    },
                ]
            });
        },
        dateFromDateStr(dateString) {
            if (isEmpty(dateString)) {
                return null
            }
            let regex = /(\d{4})年(\d{1,2})月(\d{1,2})日/;
            let matches = dateString.match(regex);
            if (!matches) {
                return null;
            }
            let year = parseInt(matches[1]);
            let month = parseInt(matches[2]) - 1; // 月份从0开始，所以需要减1
            let day = parseInt(matches[3]);
            return new Date(year, month, day);
        },
        getPastWeekRange(date) {
            // 获取过去一周的起始日期和结束日期
            let startDate = this.isWeek ? (new Date(this.anchorDate.getFullYear(), this.anchorDate.getMonth(), this.anchorDate.getDate() - 6)) : (new Date(this.anchorDate.getFullYear(), this.anchorDate.getMonth(), this.anchorDate.getDate() - 29));
            let endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            // 格式化日期字符串
            let startDateString = DateTool.dateToStr(startDate, "YYYY年MM月DD日");
            let endDateString = DateTool.dateToStr(endDate, "YYYY年MM月DD日");

            return `${startDateString}～${endDateString}`;
        }
    }
}
</script>
<style scoped lang='scss'>
@mixin flex-align {
    display: flex;
    align-items: center;
}

.abnormal-content-chart-container {
    padding: 18px 27px 24px;

    .date-info {
        @include flex-align;

        .date-info-tag {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            flex-grow: 1;
        }

        .date-info-value {
            font-size: 12px;
            color: #000000;
            flex-grow: 1;
        }

        .chart-color-info {
            @include flex-align;
            justify-content: flex-end;
            flex-grow: 3;

            &-item {
                margin-left: 16px;
                @include flex-align;

                &-color {
                    width: 8px;
                    height: 3px;
                    margin-right: 6px;
                }

                &-title {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.6);
                    height: 20px;
                    line-height: 20px;
                }
            }
        }

        /deep/.el-radio-button__inner {
            padding: 5px 22px;
        }
    }

    .info-content {
        margin: 11px 0px 16px;
    }

    .chart {}
}
</style>