<template>
    <div class="timeline-container">
        <div class="timeline-title" :class="[isToday ? 'today' : '']">{{ title }}</div>
        <div class="timeline-left">
            <div v-if="!isLast" class="timeline-line"></div>
            <div class="timeline-node" :class="[isToday ? 'today-border' : '']"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        isToday: {
            type: Boolean,
            default: false
        },
        isLast: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    },
}
</script>
<style scoped lang='scss'>
.timeline-container {
    width: 220px;
    height: calc(100% + 40px);
    position: relative;

    .timeline-title {
        padding-left: 0px;
        height: 24px;
        width: 130px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #8C8C8C;
    }

    .today {
        color: #1677FF;
    }

    .timeline-left {
        height: 100%;
        width: 30px;
        position: absolute;
        top: 6px;
        right: 34px;
        .timeline-node {
            position: absolute;
            box-sizing: border-box;
            width: 14px;
            height: 14px;
            border: 2px solid #8C8C8C;
            border-radius: 50%;
            background-color: #F2F2F7;
        }

        .today-border {
            border-color: #1677FF;
        }

        .timeline-line {
            border-left: 2px dashed #A1A1A1;
            height: 100%;
            position: absolute;
            left: 6px;
            top: 7px;
        }
    }
}
</style>