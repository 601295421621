<template>
    <el-drawer ref="drawerRef" title="工作日历详情" :with-header="false" :visible.sync="drawer" :size="800" :modal="true"
        :destroy-on-close="true" :modal-append-to-body="true">
        <work-calendar-detail-header :tabs="tabs" @close="onCloseAction" :active="activeIndex.toString()"
            @onClickTab="onClickTab">
            <keep-alive>
                <component :is="currentComponent" :timestemp="timestemp" :type="tabs[activeIndex].type" :key="activeIndex">
                </component>
            </keep-alive>

        </work-calendar-detail-header>
    </el-drawer>
</template>

<script>
import { Drawer } from 'element-ui';
import WorkCalendarDetailHeader from './work-calendar-detail-header.vue'
import PropTable from '../../common/prop-table/prop-table.vue'
import WorkCalendarAbnormal from './work-calendar-abnormal.vue'
import WorkCalendarBacklog from './work-calendar-backlog.vue'
export default {
    props: {

    },
    components: {
        ElDrawer: Drawer,
        WorkCalendarDetailHeader,
        PropTable,
        WorkCalendarAbnormal,
        WorkCalendarBacklog,
    },
    data() {
        // 日历卡片类型 all:全部, therapy_data_normal:治疗数据无异常, therapy_data_severe:治疗数据级别 高, therapy_data_moderate:治疗数据级别 中, therapy_data_mild:治疗数据级别 低
        return {
            drawer: false,
            activeIndex: 0,
            tabs: [
                {
                    label: '待办事项',
                    name: '0',
                    component: 'WorkCalendarBacklog',
                    type: '',
                },
                {
                    label: '全部',
                    name: '1',
                    component: 'WorkCalendarAbnormal',
                    type: 'all'
                },
                {
                    label: '级别：高',
                    name: '2',
                    component: 'WorkCalendarAbnormal',
                    type: 'therapy_data_severe'
                },
                {
                    label: '级别：中',
                    name: '3',
                    component: 'WorkCalendarAbnormal',
                    type: 'therapy_data_moderate'
                },
                {
                    label: '级别：低',
                    name: '4',
                    component: 'WorkCalendarAbnormal',
                    type: 'therapy_data_mild'
                },
                {
                    label: '级别：正常',
                    name: '5',
                    component: 'WorkCalendarAbnormal',
                    type: 'therapy_data_normal'
                },
                {
                    label: '未使用',
                    name: '6',
                    component: 'WorkCalendarAbnormal',
                    type: 'no_use_respirator'
                },
            ],
            timestemp: 0,
            components: []
        }
    },
    computed: {
        currentComponent() {
            return this.components[this.activeIndex];
        },
    },
    created() {
        this.components = this.tabs.map(item => item.component);
    },
    methods: {
        show(index, timestemp) {
            if (this.drawer) {
                return
            }
            this.drawer = true;
            this.activeIndex = index;
            this.timestemp = timestemp;
        },
        onCloseAction() {
            this.drawer = false;
        },
        onClickTab(index) {
            this.activeIndex = index;
        }
    },
}
</script>
<style lang='scss'>
.v-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
}
</style>