<template>
    <div class="abnormal-content-data-container">
        <div v-for="(item, index) in infoData" :key="index" class="content-item">
            <div class="content-item-title">{{ item.title }}</div>
            <color-score-item :value1="item.value1" :value2="item.value2" :level="item.level"
                font-size="15px"></color-score-item>
        </div>
    </div>
</template>

<script>
import ColorScoreItem from '@c/main/work-calendar/work-calendar-detail/color-score-item.vue'
export default {
    components: {
        ColorScoreItem,
    },
    props: {
        infoData: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            
        }
    },
    created() {

    },
    methods: {

    },
}
</script>
<style scoped lang='scss'>
.abnormal-content-data-container {
    display: flex;
    align-items: center;

    .content-item {
        display: flex;
        align-items: center;
        flex-grow: 1;

        &-title {
            display: inline-block;
            font-weight: 400;
            color: #000000;
            margin-right: 5px;
        }

        &-title::after {
            content: '：';
        }
    }
}
</style>