<template>
    <div class="package-status-item-container">
        <div v-if="packageName" class="package-status-item-desc">
            <div :class="[color]"></div>
            <div> {{ packageName }} </div>
        </div>
        <div v-else>
            -
        </div>
    </div>
</template>

<script>
export default {
    props: {
        packageStatus: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            color: '',
            packageName: ''
        }
    },
    watch: {
        packageStatus: {
            handler(value, oldValue) {
                this.packageName =
                    window.PACKAGE_STATUS.find((item) => {
                        return item.value == value
                    })?.name ?? ''
                if (value >= 0 && value <= 5) {
                    this.color = 'color-blue'
                } else if (value > 5 && value <= 11) {
                    this.color = 'color-green'
                } else if (value > 11 && value <= 14) {
                    this.color = 'color-red'
                } else {
                    this.color = ''
                }
            },
            immediate: true
        }
    },
}
</script>
<style scoped lang='scss'>
@mixin package-status-board() {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 8px;
}

.package-status-item-container {
    .package-status-item-desc {
        display: flex;
        align-items: center;
        margin-left: 3px;
    }
}

.color-red {
    background-color: #FA2424;
    @include package-status-board();
}

.color-green {
    background-color: #53CA18;
    @include package-status-board();
}

.color-blue {
    background-color: #1F91FF;
    @include package-status-board();
}
</style>