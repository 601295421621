<template>
    <div class="abnormal-content-info-container">
        <button class="info-title" @click.stop.prevent="$emit('onClickName')">
            {{ ifEmpty(exceptionDetail?.reportDate, '-') }}
        </button>
        <work-calendar-abnormal-content-data class="info-content"
            :info-data="infoData"></work-calendar-abnormal-content-data>
    </div>
</template>

<script>

import WorkCalendarAbnormalContentData from './work-calendar-abnormal-content-data.vue';
import { Divider } from 'element-ui';
import { DateTool } from '@js/date-tool'
import { ifEmpty, isEmpty } from '@js/empty-tool'
export default {
    components: {
        ElDivider: Divider,
        WorkCalendarAbnormalContentData,
    },
    props: {
        exceptionDetail: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
        }
    },
    computed: {
        infoData() {
            const info = this.exceptionDetail
            const timeStr = DateTool.secondsToHoursMinutes(info?.longTime)
            return [{
                title: 'AHI',
                value1: isEmpty(info?.ahi) ? '-' : (+info?.ahi).toFixed(1),
                value2: isEmpty(info?.ahi) ? '-' : ahiLevel(+info?.ahi)[1].toString(),
                level: ahiLevel(+info?.ahi)[0],
            }, {
                title: '使用时长',
                value1: isEmpty(info?.longTime) ? '-' : timeStr.toString(),
                value2: isEmpty(info?.longTime) ? '-' : `${timeLevel(info?.longTime)[1]}小时`,
                level: timeLevel(info?.longTime)[0],
            }, {
                title: '漏气',
                value1: isEmpty(info?.leakage) ? '-' : (+info?.leakage).toFixed(1),
                value2: isEmpty(info?.leakage) ? '-' : leakLevel(+info?.leakage)[1].toString(),
                level: leakLevel(+info?.leakage)[0],
            }, {
                title: '依从性',
                value1: isEmpty(info?.compliance) ? '-' : `${ifEmpty(info?.compliance, '-')}%`,
                value2: isEmpty(info?.compliance) ? '-' : `${complianceLevel(+info?.compliance)[1]}%`,
                level: complianceLevel(+info?.compliance)[0],
            },]
        }
    },
    created() {
    },
    methods: {
        ifEmpty,
    },
}
</script>
<style scoped lang='scss'>
.abnormal-content-info-container {
    padding: {
        top: 18px;
        left: 27px;
        right: 27px;
    }

    .info-title {
        font-size: 16px;
        font-weight: 400;
        color: #207DFF;
    }

    .info-content {
        margin: 18px 0px;
    }
}
</style>