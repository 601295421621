<template>
    <div class="card-cell-container">
        <div class="card-cell-container-content" :style="{
            '--borderColor': borderColor,
            '--backgroundColor': backgroundColor,
            '--titleColor': titleColor,
            '--mainColor': mainColor
        }" @click.passive="$emit('onSelectDetail')">
            <div class="card-cell-container-content-area1">
                <h5 class="title">{{ title }}</h5>
                <button :class="[isToday ? 'detail-today' : 'detail']" @click.stop="$emit('onSelectDetail')"
                    type="button">详情</button>
            </div>
            <div class="card-cell-container-content-area2">
                <div class="statistics">
                    <span class="statistics-left">{{ numerator }}</span>
                    <span v-if="showDenominator" class="statistics-right">/{{ denominator }}</span>
                </div>
            </div>
            <div class="card-cell-container-content-area3">
                <div v-if="subTitle || type=='green'" class="subTitle">{{ subTitle }}</div>
                <el-progress v-else class="progress" :percentage="percentage" :color="mainColor"
                    :stroke-width="8"></el-progress>
            </div>
        </div>
    </div>
</template>

<script>
import { Progress } from "element-ui";
export default {
    components: {
        elProgress: Progress
    },
    props: {
        title: {
            type: String,
            default: '',
        },

        denominator: {
            type: Number,
            default: 1,
        },

        numerator: {
            type: Number,
            default: 0
        },
        subTitle: {
            type: String,
            default: null
        },
        type: {
            validator(value) {
                return ['white', 'green', 'red', 'yellow', 'blue', 'turquoise'].includes(value)
            }
        },
        isToday: {
            type: Boolean,
            default: false
        }

    },
    data() {
        return {
            borderColorOptions: {
                white: '#1636FF',
                green: '#B7EB8F',
                red: '#FFA39E',
                yellow: '#FFE58F',
                blue: '#91D5FF',
                turquoise: '#13C2C2',
            },
            titleColorOptions: {
                white: '#8C8C8C',
                green: '#5EC829',
                red: '#FF5B5D',
                yellow: '#FAAD14',
                blue: '#2480FF',
                turquoise: '#13C2C2',
            },
            mainColorOptions: {
                white: '#1677FF',
                green: '#5EC829',
                red: '#FF5B5D',
                yellow: '#FAAD14',
                blue: '#2480FF',
                turquoise: '#13C2C2',
            },
            backgroundOptions: {
                white: '#FFFFFF',
                green: '#F6FFED',
                red: '#FFF1F0',
                yellow: '#FFFBE6',
                blue: '#E6F7FF',
                turquoise: '#E6FFFB',
            },
        }
    },
    computed: {
        percentage() {
            if (this.denominator <= 0) return 0
            if (this.numerator > this.denominator) return 0
            const value = Math.floor(this.numerator / this.denominator * 100);
            return value;
        },
        borderColor() {
            return this.type == 'white' ? (this.isToday ? this.borderColorOptions[this.type] : '#8C8C8C') : this.borderColorOptions[this.type];
        },
        titleColor() {
            return this.titleColorOptions[this.type];
        },
        mainColor() {
            return this.mainColorOptions[this.type];
        },
        backgroundColor() {
            return this.isToday ? this.backgroundOptions[this.type] : '#FFFFFF';
        },
        showDenominator() {
            return this.denominator != 0 || this.type == 'green'
        }
    },
    methods: {

    },
}
</script>
<style scoped lang='scss'>
@mixin detail-button {
    width: 60px;
    height: 30px;
    border-radius: 4px;
}

.card-cell-container {
    padding: 13px;
    box-sizing: border-box;

    &-content {
        width: 100%;
        height: 121.5px;
        border-radius: 6px;
        border: 1px solid var(--borderColor);
        background-color: var(--backgroundColor);
        box-sizing: border-box;
        cursor: pointer;

        padding: {
            left: 16px;
            right: 14px;
            top: 14px;
            bottom: 15px;
        }

        &-area1 {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .title {
                height: 20px;
                font-size: 16px;
                line-height: 20px;
                color: var(--titleColor);
                margin: 0px;
            }

            .detail {
                @include detail-button;
                border: 1px solid var(--mainColor);
                color: var(--mainColor);
            }

            .detail-today {
                @include detail-button;
                background-color: var(--mainColor);
                color: white;
            }
        }

        &-area2 {
            .statistics {
                vertical-align: bottom;

                &-left {
                    display: inline-block;
                    height: 30px;
                    font-size: 30px;
                    font-weight: 500;
                    color: var(--mainColor);
                    line-height: 30px;
                }

                &-right {
                    display: inline-block;
                    height: 16px;
                    font-size: 16px;
                    color: #8C8C8C;
                    line-height: 16px;
                }
            }
        }

        &-area3 {
            margin-top: 17px;

            .subTitle {
                height: 20px;
                font-size: 16px;
                color: var(--mainColor);
                line-height: 20px;
            }

            .progress {}
        }
    }
}</style>