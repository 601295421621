<template>
  <div>
    <work-calendar-abnormal-content-info @onClickName="onClickName"
      :exceptionDetail="exceptionDetail"></work-calendar-abnormal-content-info>
    <el-divider></el-divider>
    <work-calendar-abnormal-content-handler :text.sync="handlerText" :silence.sync="silence" @cancel="onCancel"
      @confirm="onConfirm" :exceptionDetail="exceptionDetail"
      :loading="handleLoading"></work-calendar-abnormal-content-handler>
    <el-divider></el-divider>
    <work-calendar-abnormal-content-chart :exceptionDetail="exceptionDetail"></work-calendar-abnormal-content-chart>
  </div>
</template>

<script>
import { isEmpty } from 'element-ui/src/utils/util';
import WorkCalendarAbnormalContentChart from './work-calendar-abnormal-content-chart.vue';
import WorkCalendarAbnormalContentHandler from './work-calendar-abnormal-content-handler.vue';
import WorkCalendarAbnormalContentInfo from './work-calendar-abnormal-content-info.vue';
import { Divider } from 'element-ui';
export default {
  components: {
    WorkCalendarAbnormalContentChart,
    WorkCalendarAbnormalContentHandler,
    WorkCalendarAbnormalContentInfo,
    ElDivider: Divider,
  },
  props: {
    exceptionDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      handlerText: '',
      handleLoading: false,
      silence: null
    }
  },
  watch: {
    exceptionDetail(value, oldVal) {
      if (value?.handleStatus == 2) {
        this.handlerText = '已忽略'
      } else {
        this.handlerText = value.handleMethod
      }

    }
  },
  mounted() {

  },
  methods: {
    onClickName() {
      if (isEmpty(this.exceptionDetail?.reportId)) {
        this.$message.error({
          message: '报告不存在',
          offset: 2
        });
        return
      }
      const uid = this.exceptionDetail?.patientId;
      const singleReportID = this.exceptionDetail?.reportId;
      const hospitalId = this.exceptionDetail.hospitalId;
      if (!uid || !singleReportID) {
        return;
      }
      //跳转报告详情
      let routeData = this.$router.resolve({
        path: "/report-frame",
        query: {
          uid,
          singleReportID,
          hospitalId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    onCancel() {
      this.exceptionHandling(2)
    },
    onConfirm() {
      this.exceptionHandling(1)
    },
    async exceptionHandling(handleStatus) {
      this.handleLoading = true;
      try {
        const data = await this.$api.getWorkCalendarExceptionHandling({
          id: this.exceptionDetail.id,
          handleStatus,
          handleMethod: this.handlerText
        })
        if (!data) {
          this.$message({
            message: `${handleStatus == 1 ? '处理' : '忽略'}失败`,
            offset: 2
          });
        } else {
          this.$emit('refresh')
          //处理成功右上角小铃铛刷新
          this.$store.dispatch('getRemindCount')
        }
      } catch (error) {
        this.$message({
          message: error,
          offset: 2
        });
      } finally {
        this.handleLoading = false;
      }
    }
  },
}
</script>
<style scoped lang='scss'>
.el-divider {
  margin: 0px;
}
</style>