<template>
    <el-dialog class="dialog" :visible.sync="dialogVisibleRef" :width="dialogWidth" :style="{ 'min-width': dialogWidth }"
        align-center :show-close="false" @close="$emit('close')" append-to-body top="0">
        <template #title>
            <div class="dialog-header-content">
                <div class="dialog-header-content-left">
                    <button class="dialog-header-content-left-title" @click.stop.prevent="$emit('onClickHeaderTitle')">{{ dialogTitle }}</button>
                    <div class="dialog-header-content-left-desc"> {{ dialogDesc }} </div>
                </div>
                <button class="dialog-header-content-close" @click.prevent="$emit('close')">
                    <i class="el-icon-close"></i>
                </button>
            </div>
            <el-divider />
        </template>
        <slot></slot>
    </el-dialog>
</template>

<script>
import { Dialog, Divider } from 'element-ui';
export default {
    components: {
        ElDialog: Dialog,
        ElDivider: Divider
    },
    props: {
        dialogTitle: {
            type: String,
            default: ''
        },
        dialogDesc: {
            type: String,
            default: ''
        },
        dialogWidth: {
            type: String,
            default: ''
        },
        dialogVisible: {
            type: Boolean,
            default: ''
        },
    },
    data() {
        return {
            top:'0px'
        }
    },
    computed: {
        dialogVisibleRef: {
            get() {
                return this.dialogVisible
            },
            set(value) {
                this.$emit('update', value)
            }
        },

    },
}
</script>
<style scoped lang='scss'>
.dialog {
    &-header-content {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 27px;

        &-left {
            &-title {
                display: inline-block;
                font-size: 16px;
                font-weight: 400;
                color: #1677FF;
                line-height: 22px;
                height: 22px;
                max-width: 200px;
            }

            &-desc {
                display: inline-block;
                margin-left: 20px;
                height: 22px;
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
                line-height: 22px;
            }
        }
        &-close {
            background-color: transparent;
            border: 0px;
            width: 30px;
            height: 30px;
            color: black;
            font-size: 18px;
        }
    }

    /deep/.el-dialog__header {
        padding: 0px;
        margin: 0px;
    }
    /deep/.el-dialog__body {
        padding: 0px;
    }

    .el-divider {
        margin: 0px;
    }
}
</style>
<style>

.el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.el-dialog {
   margin: 0 auto;
}
</style>