<template>
  <div class="work-calendar-detail-header-container">
    <div class="work-calendar-detail-header">
      <el-tabs v-model="activeModel">
        <el-tab-pane v-for="item, index in tabs" :label="item.label" :name="item.name" :key="item.name">
        </el-tab-pane>
      </el-tabs>
      <button class="work-calendar-detail-close" type="button" @click.stop="$emit('close')">
        <i class="el-icon-close work-calendar-detail-close-icon"></i>
      </button>
    </div>
    <el-divider></el-divider>
    <slot></slot>
  </div>
</template>

<script>
import { Tabs, TabPane, Divider } from 'element-ui';
export default {
  components: {
    ElTabs: Tabs,
    ElTabPane: TabPane,
    ElDivider: Divider
  },
  props: {
    tabs: {
      type: Array,
      default: []
    },
    active: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {

    }
  },
  computed: {
    activeModel: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit('onClickTab', +val ?? 0)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
  },
}
</script>
<style scoped lang='scss'>
.work-calendar-detail-header-container {
  .el-divider {
    margin: 0px;
  }
}

.work-calendar-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  /deep/.el-tabs__header {
    margin: 0px;
    margin-left: 20px;
    // height: 48px;
  }

  /deep/.el-tabs__item {
    height: 48px;
    line-height: 48px;
  }

  /deep/.el-tabs__nav-wrap::after {
    background-color: transparent;
  }
  /deep/.el-tabs__item.is-active,
  /deep/.el-tabs__item:hover {
    color: #0052D9;
}
/deep/.el-tabs__active-bar {
  background-color: #0052D9;
  height: 3px;
}

  .work-calendar-detail-close {
    width: 48px;
    height: 48px;
    margin-right: 5px;

    &-icon {
      font-size: 20px;
    }
  }
}
</style>