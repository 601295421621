<template>
    <div class="color-score-item-container">
        <div v-if="!isEmpty(value1)" class="item-left" :class="[color]" :style="{'fontSize':fontSize}"> {{ value1 }} </div>
        <div v-if="!isEmpty(value2)" class="item-right" :style="{'fontSize':fontSize}"> {{ `/${value2}` }} </div>
    </div>
</template>

<script>
import { isEmpty } from "@js/empty-tool.js"
export default {
    props: {
        value1: {
            type: String,
            default: '',
        },
        value2: {
            type: String,
            default: '',
        },
        level: {
            type: Number,
            default: 0,
        },
        fontSize: {
            type: String,
            default: '12px',
        }
    },
    data() {
        return {
            color: '',
        }
    },
    watch: {
        level: {
            handler(value, oldValue) {
                if (value == 0) {
                    this.color = 'color-black'
                } else if (value == 1) {
                    this.color = 'color-blue'
                } else if (value == 2) {
                    this.color = 'color-yellow'
                } else {
                    this.color = 'color-red'
                }
            },
            immediate: true
        }
    },
    methods: {
        isEmpty
    }
}
</script>
<style scoped lang='scss'>
.color-score-item-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .item-left {
    }

    .item-right {
        color: rgba($color: #000000, $alpha: 0.25); 
    }
}

.color-red {
    color: #FF7374;
}

.color-blue {
    color: #2480FF;
}

.color-yellow {
    color: #FAAD14;
}

.color-black {
    color: black;
}
</style>